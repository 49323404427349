<template>
  <div class="batchBox">
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
      </div>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label">商品名称</template>
          {{detail.name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品分类</template>
          {{detail.category_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品规格</template>
          {{detail.spec_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">金蝶编码</template>
          {{detail.kingdee_code}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品金蝶销售单位编码</template>
          {{detail.unit_code}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品单位名称</template>
          {{detail.unit_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品销售价格</template>
          {{detail.price}}元
        </el-descriptions-item>
       <el-descriptions-item>
          <template slot="label">商品货号</template>
          {{detail.goods_sn}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品类型</template>
          {{detail.is_project == 1?'商品':'服务'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否上架</template>
          {{detail.is_project == 1?'是':'否'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商品备注</template>
          {{detail.desc}}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { goodsGetail } from '@/api/goods';
class Detail {
    name='';
    spec_name='';
    kingdee_code='';
    unit_code='';
    price='';
    goods_sn='';
    desc='';
    is_project = 1; 
    status=1;
    is_project=1;
}
export default {
  data () {
    return {
      detail: new Detail(),
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail(){
       goodsGetail({id:this.$route.query.id} ).then(res => {
        this.detail = res;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: #f9fafc;
}
</style>
